import React, {
  createContext,
  useReducer,
  Dispatch,
  useCallback,
  useMemo,
} from "react";
import { appNotification, loadingIndicator } from "../core";
import {
  leadsService,
  quickRepliesService,
  mediaUploadService,
} from "../services";
import { Lead } from "../types/Lead";
import { QuickReply } from "../types/Shortcodes";

export interface ChatMessageBoxStateActions {
  type: string;
  value?: any;
}

export interface ChatMessageBoxState {
  emojiOpen: boolean;
  showAllTools: boolean;
  internalOnly: boolean;
  isEmail: boolean;
  showMentionsPopup: boolean;
  showQuickReplies: boolean;
  selectedMentionIndex: number;
  notificationGroups: any[];
  allNotificationGroups: any[];
  selectedGif?: any;
  selectedFile?: any;
  selectedFilePreview?: any;
  selectedFiles?: any;
  selectedFilePreviews?: any;
  shortcode?: string;
}

export interface ChatMessageBoxContextProps {
  state: ChatMessageBoxState;
  dispatch: Dispatch<ChatMessageBoxStateActions>;
  isEmptyMessage: () => boolean;
  appendMessage: (value: string) => void;
  onFileSelected: (file: any) => Promise<any>;
  onFilesSelected: (files: any) => Promise<any>;
}

export const ChatMessageBoxContext = createContext(
  {} as ChatMessageBoxContextProps
);

export function getMessageInput(): HTMLIonInputElement | null {
  // Get all elements with the class 'chat-message-box-input'
  const inputs = document.getElementsByClassName("chat-message-box-input");

  // Loop through each input element and find the visible one
  for (let i = 0; i < inputs.length; i++) {
    const input = inputs[i] as HTMLIonInputElement;
    const isVisible = input.offsetParent !== null; // Check if element is visible

    if (isVisible) {
      return input;
    }
  }

  // If no visible input is found, return null
  return null;
}

export function getMessageText(): string {
  const element = getMessageInput();
  return (element?.value ?? "").toString();
}

export function setMessageText(text: string) {
  const element = getMessageInput();

  if (element) {
    element.value = text;
  }
}

export function expandQuickReply(reply: QuickReply, lead?: Lead) {
  const msg = getMessageText();
  setMessageText(
    `${msg.substring(
      0,
      msg.lastIndexOf(":")
    )}${quickRepliesService.renderedMessageText(reply, lead)}`
  );
}

export function appendMessageText(text: string) {
  const element = getMessageInput();

  if (element) {
    element.value += text;
    // Dispatch an ionInput event for Ionic components
    const ionInputEvent = new CustomEvent("ionInput", {
      bubbles: true,
      detail: { value: element.value },
    });
    element.dispatchEvent(ionInputEvent);
  }
}

export function replaceMessageText(
  getIndex: (str: string) => number,
  text: string
) {
  const element = getMessageInput();
  if (element) {
    const msg = (element.value ?? "").toString();
    const index = getIndex(msg);
    element.value = `${index > -1 ? msg.substring(0, index) : msg}${text} `;
  }
}

export function clearMessage() {
  setMessageText("");
}

export function setMessageFocus() {
  const element = getMessageInput();
  element?.setFocus?.();
}

let reducer = (state: ChatMessageBoxState, action: any) => {
  const { type, value } = action;

  switch (type) {
    case "setMention": {
      const user =
        value || state.notificationGroups[state.selectedMentionIndex ?? 0];
      if (!user) return state;
      const mention = `@${user.username}`;

      replaceMessageText((msg: string) => msg.lastIndexOf("@"), mention);

      return {
        ...state,
        showMentionsPopup: false,
        internalOnly: true,
        isEmail: false,
        notificationGroups: [],
        selectedMentionIndex: 0,
      };
    }

    case "set": {
      return { ...state, ...value };
    }
  }
  return state;
};

export const ChatMessageBoxContextProvider = (props: any) => {
  const initialState: ChatMessageBoxState = {
    emojiOpen: false,
    showMentionsPopup: false,
    showQuickReplies: false,
    selectedMentionIndex: 0,
    notificationGroups: [],
    allNotificationGroups: [],
    showAllTools: false,
    internalOnly: false,
    isEmail: false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const isEmptyMessage = useCallback(() => {
    const msg = getMessageText();
    return (
      !msg?.trim() &&
      !state.selectedGif &&
      !state.selectedFile &&
      !state.selectedFiles?.length
    );
  }, [state.selectedGif, state.selectedFile, state.selectedFiles]);

  const onFilesSelected = useCallback(
    async (fileList: FileList) => {
      await loadingIndicator.create();
      const files = Array.from(fileList); // Convert FileList to an array
      const newFilePreviews: string[] = [];
      const newUploadedFiles: any[] = [];

      for (const file of files) {
        if (!leadsService.isSupportedFileType(file.type, state.isEmail)) {
          const msg = state.isEmail
            ? "That file type is not supported."
            : "Only images and videos are supported in MMS. Try sending as an email.";
          appNotification.toast(msg, "Error");
          continue; // Skip unsupported file types
        }

        // Upload file to the service
        try {
          const uploadedFile = await mediaUploadService.upload(file);
          if (uploadedFile && uploadedFile.url) {
            newUploadedFiles.push(uploadedFile);

            // Use google storage url to produce video preview
            if (file.type.startsWith('video/')) {
              newFilePreviews.push(uploadedFile.url);
            } else {
              const img = new Image();
              await new Promise<void>((resolve) => {
                img.onload = () => resolve();
                img.src = uploadedFile.url;
              });
              newFilePreviews.push(uploadedFile.url);
            }
          }
        } catch (error) {
          console.error("Error uploading file:", error);
          appNotification.toast(
            "Error uploading file. Please try again.",
            "Error"
          );
        }
      }

      // Combine existing state values with new ones
      const updatedFilePreviews = [
        ...(state.selectedFilePreviews || []),
        ...newFilePreviews,
      ];
      const updatedUploadedFiles = [
        ...(state.selectedFiles || []),
        ...newUploadedFiles,
      ];

      // Dispatch the updated state
      dispatch({
        type: "set",
        value: {
          selectedFilePreviews: updatedFilePreviews,
          selectedFiles: updatedUploadedFiles,
        },
      });

      loadingIndicator.dismiss();
    },
    [dispatch, state.isEmail, state.selectedFilePreviews, state.selectedFiles]
  );

  const onFileSelected = useCallback(
    async (file: any) => {
      return new Promise<void>(async (resolve) => {
        if (!leadsService.isSupportedFileType(file.type, state.isEmail)) {
          const msg = state.isEmail
            ? "That file type is not supported."
            : "Only images and videos are supported in MMS.  Try sending as an email.";
          appNotification.toast(msg, "Error");
          return resolve();
        }

        try {
          loadingIndicator.create();
          const uploadedFile = await mediaUploadService.upload(file);
          if (uploadedFile && uploadedFile.url) {

            // No need to use FileReader. Just use the URL directly.
            dispatch({
              type: "set",
              value: {
                selectedFilePreview: uploadedFile.url || '', // Set preview URL
                selectedFile: file,
              },
            });

            resolve();
          }
        } catch (error) {
          console.error("Error uploading file:", error);
          appNotification.toast(
            "Error uploading file. Please try again.",
            "Error"
          );
        } finally {
          loadingIndicator.dismiss();
        }
      });
    },
    [dispatch, state.isEmail]
  );

  const value = useMemo(
    () =>
      ({
        state,
        dispatch,
        isEmptyMessage,
        appendMessage: appendMessageText,
        onFileSelected,
        onFilesSelected,
      }) as ChatMessageBoxContextProps,
    [state, dispatch, isEmptyMessage, onFileSelected, onFilesSelected]
  );

  return (
    <ChatMessageBoxContext.Provider value={value}>
      {props.children}
    </ChatMessageBoxContext.Provider>
  );
};

export const ChatMessageBoxContextConsumer = ChatMessageBoxContext.Consumer;
